import React from 'react'
import { graphql } from 'gatsby'
import { parseSeo } from '../../helpers/seo'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { Text } from '../../components/Text'
import { IndentWrapper } from '../../blocks/IndentWrapper'
import { Grid } from '../../components/Grid'
import { Heading } from '../../components/Heading'
import { Divider } from '../../components/Divider'
import { Container } from '../../components/Container'
import { MapHeader } from '../../components/MapHeader'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Content } from '../../components/Content'
import { TextLink } from '../../components/TextLink'
import { PageContent } from '../../components/PageContent'
import { COLORS, SPACING } from '../../helpers/constants'
import { getUrl } from '../../helpers/url'

export default ({ data, pageContext }) => {
  const { page, dealerCountries } = data.wordpress
  const {
    seo,
    acfPageTemplateDealersCountryListing: { title, text, dealersMapPageLink },
  } = page

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <MapHeader link={dealersMapPageLink} />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      <PageContent>
        <Container>
          <IndentWrapper col={3}>
            <Content>
              <Heading type={'h1'} size={1} color={COLORS.PRIMARY_RED}>
                {title}
              </Heading>
              <Text columns={2}>{text}</Text>
            </Content>
            <Divider size={SPACING.MEDIUM} />

            <Grid col={2} rowGap={SPACING.SMALL}>
              {dealerCountries.nodes.map((country, index) => {
                return country ? (
                  <TextLink
                    key={index}
                    to={getUrl(country.uri)}
                    icon={'keyboard_arrow_right'}
                    text={country.name}
                  />
                ) : null
              })}
            </Grid>
          </IndentWrapper>
        </Container>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!, $lang: WordPress_LanguageCodeFilterEnum) {
    wordpress {
      page(id: $id) {
        ...Single_Page
        acfPageTemplateDealersCountryListing {
          title
          text
          dealersMapPageLink {
            title
            url
          }
        }
      }
      dealerCountries(first: 100, where: { language: $lang }) {
        nodes {
          ...Single_Dealer_Country_Category
        }
      }
    }
  }
`
